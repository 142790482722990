import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from "styled-components"

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  border-top: 20px solid #dadada;
  padding: 2rem 0 2rem 0;
`

const Intro = styled.div`
    color: #333;
    font-size: 1.8rem;
    grid-column: 1 / 3;
    line-height: 1.6;
    margin-bottom: 4rem;
    padding-right: 4rem;

    @media only screen and (max-width: 768px) {
      grid-column: 1 / 1;
      margin-bottom: 3rem;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      margin-top: 1rem;
      font-size: 3rem;
      line-height: 1.3;
    }
`

const Quote = styled.div`
  line-height: 1.8;
  font-size: 1.8rem;
  color: #333;
  font-family: 'Literata', serif;

  border-bottom: 1px solid #dadada;
  padding: 3rem 0;
  p {
      padding-bottom: 1rem;
  }
`
const Name = styled.a`
    font-style: normal;
    font-family: 'Montserrat', sans-serif;

    font-weight: 600;
    color: #333;
    font-size: 1.6rem;
    padding-top: 1rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        color: green;
    }
`

const Button = styled.button`
  padding: 14px 14px;
  background-color: #0D5C00;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 60%;
  
  &:hover {
    background-color:#00B227;
    color: #fff;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
    width: 100%;
  }
`


class ReferenceIndex extends React.Component {
    render() {
      const { location, title, children } = this.props
      const rootPath = `${__PATH_PREFIX__}/`
      let header
  
      return (
        <Layout>
          <SEO
            title="The People Speak"
            keywords={[`curriculum`, `product design`, `UX design`, `user experience`]}
          />
            <img src="https://newprag.s3.amazonaws.com/how_it_works/welcome.png"/>
            <Intro>
              <Headline>Testimonials</Headline>
              <p>New Pragmatic founder and mentor Chris Courtney is an award-winning designer and instructor with over two decades of experience in design and education. Chris has led or created design courses at Northwestern University, Columbia College Chicago, Bloc.io, and Thinkful.com. Here’s what some of his students and colleagues had to say about working with him.</p>
              <a href="/mentorship"><Button>Learn more about mentorship</Button></a>
            </Intro>
            
              <Title>Students</Title>
              <Quote>
                  <p>Chris is a wonderful mentor, a great human, and also a perfect fit for my learning style. I wanted someone who could lead me through this program in a no-nonsense and practical manner (after auditing college courses for design and user experience, I was tired of professors reading notes and regurgitated slides at me), to guide me through the process of designing, learning, and building. He gave me the space to grow and grapple with my own challenges, but was always there to provide support when I needed it.</p>
                  <p>There is no hand-holding, only constructive, useful feedback, helpful tips/nudges, and a boatload of encouragement. Chris went above and beyond the curriculum, never hesitating to spend extra time tackling a problem with me, and always set the expectations and standards high.</p>
                  <Name href="https://www.linkedin.com/in/juliamatsuoka/" target="_blank">Julia Matsuoka, Product Manager @ Zingle</Name>
              </Quote>
              <Quote>
                  <p>When I started working with Chris, I just wanted to learn how to code. I had 10+ years of design experience and I didn't think that I could grow much more in that area. I was wrong.</p>
                  <p>Along with building a strong base in frontend fundamentals, my design skills flourished under Chris's mentorship. With his help, I pushed beyond my comfort zone and developed a design process that is centered on the user experience.</p>
                  <p>Chris has opened many doors for me and I continue to lean on his lessons today.</p>
                  <Name href="https://www.linkedin.com/in/ben-howard-design/" target="_blank">Ben Howard, Experience Design Lead @ PNC</Name>
              </Quote>
              <Quote>
                  <p>I really could go on and on about Chris — I can tend to be long winded — but I will just mention a couple specific things here:</p>
                  <p>1. A Curriculum for Me. Recently in a job interview(*) for a product designer role I was asked about my experience at Bloc and making a career change. Of course I talked about design process and my projects, but really a major part of my answer related to Chris. I came to this career change and enrolling in Bloc having a wide variety of experiences across a number of different industries. From the start of our first meeting, Chris made it a priority to understand me and those experiences and helped me develop my voice as a designer with those experiences in mind. He would personalize areas of the curriculum according to my specific interests/passions. Ultimately, he helped me develop my story and my narrative for the designer I am now today. This is crucially important.</p>
                  <p>2. A Rock. While I was doing this program I was also working full-time at a job and there were definitely times I felt like I bit off more than I could chew. But thankfully enter Chris. He made himself available for additional mini check-ins outside of our regular meeting to keep me moving and motivated and wouldn't hesitate to going overtime on our regular call to help me work through problems. That being said, by no means did he go easy on me -- Chris pushed, challenged and stretched me as a designer that constantly shocks me how far I've come in my time with Bloc and Chris.</p>
                  <p>The impact that Chris had on my life/career is hard to put into words without sounding totally over the top. Thanks to Chris I landed the job from the interview mentioned above(*) just a couple weeks after completing the program. Chris is the modest kind who tells me that that was all me, but really it was him too, and for that I am forever thankful.</p>
                  <Name href="https://www.linkedin.com/in/samanthahankins/" target="_blank">Sam Hankins, Senior Product Designer @ VOX Media</Name>
              </Quote>
              <Quote>
                  <p>Chris is one of those special teachers. He is a wealth of knowledge and experience and can break down any concept no matter how seemingly complex. He knows more about your potential than you do. He knows when you need a pat on the back and he knows when you need to be pushed. Most importantly, he is just as invested in your success as you are.</p>
                  <p>When I was down-and-out and ready to give up on design he picked me up, brushed me off and gave me the support I needed to keep going. The skills I developed working with Chris allowed me to change my path in life. Whether you're looking to change your career or up your design game Chris will show you how and be there for you every step of the way.</p>
                  <Name href="https://www.linkedin.com/in/shane-a-williams/" target="_blank">Shane Williams, Project Manager @ Lenovo</Name>
              </Quote>
              <Quote>
                  <p>I can't properly articulate how wonderful Chris is as both a mentor and human. In short: He's pretty special. Not only is he extraordinarily talented and a competent mentor/teacher, he goes above and beyond in ensuring his apprentices achieve success. Whether it was scheduling a second mentor call the same day to delve more deeply into an issue, or sending an empathetic, encouraging email during times of doubt, Chris has the mentor/friend thing nailed. He also gives his apprentices additional tips and anecdotes regarding real-world experiences that aren't included in the curriculum. He goes above and beyond, always. Our final call was two hours long, and Chris didn't even flinch in spending that much time with me.</p>
                  <p>I could write an extended short non-fiction piece about how Chris has positively impacted my existence, but I'll save that for another day. I'm genuinely bummed I don't get to work with him on a daily basis, but my time with him got me a full time XD position, so there's that.</p>
                  <Name href="https://www.linkedin.com/in/martafieweger/" target="_blank">Marta Fieweger, Senior User Experience Designer at BORN Group</Name>
              </Quote>
              <Quote>
                  <p>There’s a difference between working with someone who simply wears the title of a mentor, and a master of their craft who truly understands how to help a student grow from where they are right now to exactly where they want to be.</p>
                  <p>Chris Courtney is the real deal; he personifies what it means to be a mentor and teacher. I started working with Chris after a year of learning front-end development on my own and occasional freelance design work before that. My goal was to break out of the classroom as a High School Teacher and into my dream career as a Designer. This was no small venture continuing to work full-time and my wife and I expecting our first child by the graduation date, but Chris was absolutely all-in from the beginning to help me achieve that career-changing outcome.</p>
                  <p>To say he’s dedicated is an understatement and that’s what I loved about working together. Every single meeting, Chris brought his full presence, energy, passion, and experience. He shared insights that you can’t just find in a tutorial and emphasized understanding the design process over blindly chasing industry trends and tools shining in the moving spotlight. Chris was genuine and completely honest with feedback and enthusiastically encouraging when it was needed most. He used the curriculum as a framework, but always knew when to challenge me to go further and lean into things that felt uncomfortable (because that’s the secret sauce to true growth and developing confidence). And it goes without saying, Chris was always there.</p>
                  <p>Whether it was going well overtime during a Skype call, answering a thousand questions, or sending positive actionable advice during the job search weeks after our last meeting; there was never a moment that I did not feel supported in the journey. Like Marta, I too could write a novel about the impact Chris Courtney has made on my life and career. As a newly-minted User Experience Designer, I hope our paths will cross professionally in the future, but I’m happy that Chris will always be a trusted friend.</p>
                  <Name href="https://www.linkedin.com/in/justingolownia/" target="_blank">Justin Golownia, Senior Product Designer at CoConstruct</Name>
              </Quote>
              <Quote>
                  <p>Chris Courtney is exactly what you would expect of a dedicated, supportive and encouraging mentor, and then some. He is an incredibly genuine person; he works with you with your success in mind, pushing you to achieve nothing less than your best. He constructively examines your work, providing you with necessary feedback and is beyond willing to spend extra time tackling a specific hurdle with you.</p>
                  <p>Chris knows his onions! He took the time to imbue me with specific design principles, tips, tricks, and resources that truly benefited by craft and practice. Above all, Chris is a really nice human being.</p>
                  <p>At the end of my program Chris has continued to mentor and support my endeavors (giving feedback on my portfolio, giving great interview tips, providing references for my first internship hire).</p>
                  <Name href="https://www.linkedin.com/in/zoechinonsoene/" target="_blank">Zoë Chinonso Ene, Designer at Microsoft</Name>
              </Quote>
              <Quote>
                  <p>Chris was the most incredible teacher and mentor to all of us. His knowledge, expertise, empathy and guidance far surpassed my expectations. He truly is the “Unicorn” designer/developer. To this day I still look at his front-end development and design content when I'm in rut. I truly feel fortunate to have learned from him and I wouldn't be the designer I am today without his guidance. Chris is freakin' awesome!.</p>
                  <Name href="https://www.linkedin.com/in/johnkbalboa/" target="_blank">John Balboa, Product Designer @ National Park Express</Name>
              </Quote>
              <Title>Colleagues</Title>
              <Quote>
                  <p>I have worked for and reported to many “good” managers. Chris is in an entirely different league.</p>
                  <p>I am extremely fortunate to work with Chris at Bloc. As Director of the Design program, he spearheaded the creation of our current curriculum and continues to work relentlessly to improve it based on mentor and student feedback. Aside from being an amazing designer, he is an incredible manager who consistently sets an example for the people who report directly and indirectly to him; if he asks our senior design staff, myself included, to dive into a queue of work he is always right there in the queue with us. In addition to that his unwavering support of the senior design team shows all of us that he trusts us to evolve and build upon the curriculum in order to make the program even stronger. He is a servant leader in every sense of the phrase, and constantly reminds me how lucky our team is to have him guiding us.</p>
                  <p>“Senior leaders and mentors at Bloc frequently look to the Design program to see how they can improve their own courses, curricula and student interactions, and that’s one of the reasons why I am so proud of what the Design program has achieved and how Chris continues to inspire all of us and every student in our program. Did I mention that on top of everything that he does, he also critiques every Designer Track student’s portfolio, sets aside dedicated time each weekday to meet with students for a design critique, and is still excited to write new curriculum?!</p>
                  <p>Chris Courtney is superhuman and puts everything he has into this program. It is incredibly inspiring to have someone like Chris knocking down walls and paving the way for all of us at Bloc. He is a fierce advocate for our design program, its mentors and students, and I consider myself very lucky to be on the team that he leads.</p>
                  <Name href="https://www.linkedin.com/in/alissalikavec/" target="_blank">Alissa Likavec, Web Design Evangelist @ Amazon</Name>
              </Quote>
              <Quote>
                  <p>When putting together a team focused on mobile products and applications, it's crucial to find the right design / UX talent. Chris brought his energy, passion and experience to the group and made significant contributions to our mobile products and research projects. For anyone that is lucky enough to have Chris on their team, expect him to bring a perfect balance of talent, camaraderie, curiosity and focus.</p>
                  <Name href="https://www.linkedin.com/in/jonathanozeran/" target="_blank">Jonathan Ozeran, Director of Product @ Tempus</Name>
              </Quote>
              <Quote>
                  <p>Chris Courtney is a supremely talented, innovative, visionary journalist who was instrumental in the development of RedEye—a newspaper industry success story—and several RedEye spinoff projects. He is a change agent who continually pushes himself and those around him in his relentless pursuit of innovation and improvement. He possesses high-level skills in every aspect of design: illustration, photo, graphics, color, typography, etc.</p>
                  <p>Beyond those design skills, he possesses leadership and strategic planning skills. He is a staff influencer who leads by example, approaching projects with a can-do, passionate attitude. He has a proven ability to handle large projects single-handedly, thinking them through and executing beginning to end. He is an asset to any team, and his skills are transferrable outside the newspaper industry.</p>
                  <Name href="https://www.linkedin.com/in/janehirt/" target="_blank">Jane Hirt, Consultant</Name>
              </Quote>
              <a href="/mentorship"><Button>Sign up for mentorship now!</Button></a>

            
        </Layout>
      )
    }
  }
  
  export default ReferenceIndex